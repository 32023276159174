import React from "react";
import ButnotP from "../Styles/butnotP";
import '../Styles/Debt_form.css'
import RedditTextField from "./reddit-textfield";
import { IMaskInput } from 'react-imask';
import PropTypes from 'prop-types';
import ScrollDialog from "./Dialog";
import { publicFetch } from "../util/fetch";
import { Spinner } from "react-bootstrap";
import { MdOutlineDone } from "react-icons/md";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="+7 (000) 000-0000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            lazy={false}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

TextMaskCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export const DebtForm = () => {
    const [values, setValues] = React.useState({
        textmask: '',
        username: '',
        birthdate: '',
    });
    const [checked, setChecked] = React.useState(false)
    const [debtSuccess, setDebtSuccess] = React.useState() 
    const [debtError, setDebtError] = React.useState()
    const [debtLoading, setDebtLoading] = React.useState(false)

    const handleChange = (event) => {
        setValues({
          ...values,
          [event.target.name]: event.target.value,
        });
    };

    const handleChange1 = (e) => {
        const value = e.target.value
        setValues({
          ...values,
          [e.target.name]: value
        })
    };

    const submitCredentials = async credentials => {
        credentials.preventDefault()
        const Credentials = {
          username : values.username,
          birthdate: values.birthdate,
          textmask : values.textmask,
          pageId: 1
        }
        try {
          if (checked === true) {
                setDebtLoading(true)
                const {data} = await publicFetch.post(`api/formreqs`, Credentials)
                setDebtError('');
                setTimeout(() => {
                setDebtSuccess(data.message);
                }, 1100);
            } else {
                setDebtError(`Необходимо ознакомиться с Политикой компании в отношении обработки персональных данных`)
            }
        } catch (error) {
            setDebtLoading(false);
            const {data} = error.response;
            setDebtError(data.message);
            setDebtSuccess('');
        }
    }
    // 

    const handleBackClick = () => {
        setDebtLoading(false)
        setDebtSuccess(!debtSuccess)
    }
    
    return (
        <div className='formGroupD'>
            {!debtSuccess ? (
                <div className="DebtFormContainer">
                    <label className="FormLabel">Узнайте о наличии задолженности</label>
                    <form className='ECformD' onSubmit={values => submitCredentials(values)}>
                        <div >
                            <RedditTextField required id='username' value={values.username} type='text' label='Фамилия Имя Отчество' onChange={handleChange} InputLabelProps={{ shrink: true }} variant='filled' name='username'/>
                        </div> 
                        <div>
                            <RedditTextField required id='date' label='Дата рождения' value={values.date} type='date' InputLabelProps={{ shrink: true }} variant='filled' name='birthdate' onChange={handleChange} InputProps={{ disableUnderline:true }} inputProps={{ min: "1950-05-01", max: "2023-05-04"}}/>
                        </div> 
                        <div>
                            <RedditTextField required variant='filled' label='Контактный номер' value={values.textmask} InputLabelProps={{ shrink: true }} onChange={handleChange1} name='textmask' id='formatted-text-mask-input' InputProps={{inputComponent: TextMaskCustom, disableUnderline: true}}/>  
                        </div> 
                        <ButnotP type='submit' id='ButnotP3' name={debtLoading === true ? (<Spinner animation="border" style={{marginTop:'6px'}}/>) : (<span>Продолжить</span>)} style={{width:'200px', height:'59px', fontSize:'12pt', borderRadius:'5px'}}/> 
                    </form>
                    <div className='PrivacyRow1'>
                        <ScrollDialog checked={checked} setChecked={setChecked} id='DebtCheck'/>
                        <span>{debtError}</span>
                    </div>
                </div>
            ) : (
                <div className='debtMessage' style={debtSuccess ? {opacity: '1'} : {opacity: '0'}}>
                    <MdOutlineDone className='debtIcon'/>
                    {debtSuccess}
                    <button type='button' id='debtButtonBack' onClick={handleBackClick}>Вернуться назад</button>
                </div>
            )}
        </div>
    )
}