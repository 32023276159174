import React from 'react'
import { Container } from 'react-bootstrap'
import ButnotS from '../Styles/butnotS'
import './AnCall.css'
import src from '../Styles/ECPhone2.png'
import whatsapp from '../Icons/whatsapp.svg'
import telegram from '../Icons/telegram.svg'
import { FaVk } from 'react-icons/fa'
import { tel } from '../Styles/colors'

export default function AnCall() {
    const preventDragHandler = (e) => {
        e.preventDefault()
    }
    return (
        <Container className='ACcol0'>
            <Container className='ACcol1'>
                <h1 className='AC-h1'>Анонимный звонок</h1>
                <hr ></hr>
                <p className='AC-p'>Вы можете получить бесплатную и анонимную консультацию по любым вопросам погашения задолженности. Мы подскажем, что надо сделать, чтобы получить рассрочку с прощением части долга.</p>
                <Container className='AC-row'>
                    <h2><a href='tel:83412940707' id='ANCphone'>{tel}</a> 
                        <p>Пн-Пт, с 8-00 до 17-00 МСК</p>
                    </h2>
                    <h2><a href='mailto:collect@ec18.ru' id='ANCmail'>collect@ec18.ru</a>
                        <p>или напишите нам</p>
                    </h2>
                </Container>
                <Container className='AC-row'>
                    <a href='/signup'>
                        <ButnotS name='Узнать задолженность безопасно' id='AnCallB'></ButnotS>
                    </a>
                    <a href='https://wa.me/79090559988'>
                        <img src={whatsapp} alt='whatsapp' onDragStart={preventDragHandler}/>
                    </a>
                    <a href='https://telegram.me/expresscollection'>
                        <img src={telegram} alt='telegram' onDragStart={preventDragHandler}/>
                    </a>
                    <a href='https://vk.com/club217586890'>
                        <FaVk id='ANVK' onDragStart={preventDragHandler}/>
                    </a>
                </Container>
            </Container>
            <Container className='ACcol2'>
                <img src={src} alt='AnCal' onDragStart={(event)=> event.preventDefault()} style={{pointerEvents:'none'}}>   
                </img>
            </Container>
        </Container>
    )
}
