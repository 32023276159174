import React from 'react'
import { Container, Fade, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import src from '../Styles/ECLOGOSVG.svg'
import '../Styles/Text_elems.css'
import ButnotP from '../Styles/butnotP'
import { AuthContext } from '../context/AuthContext'
import { tel } from '../Styles/colors'

export default React.memo(function NaviBar() {
    const authContext = React.useContext(AuthContext)
    const [open, setOpen] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [show1, setShow1] = React.useState(false);
    
    const width = window.innerWidth
    const breakpoint = 480

    const showDropdown = (e) => {
        setShow(!show)
    }
    const hideDropdown = (e) => {
        setShow(false)
    }
    const showDropdown1 = (e) => {
        setShow1(!show1)
    }
    const hideDropdown1 = (e) => {
        setShow1(false)
    }

  return (
    <>
        <Navbar className='navBarEC ml-auto' collapseOnSelect expand='sm' variant='dark' >
            <Container>
                <Navbar.Brand href='/' className='navBarEClogo'>
                    <img
                        src={src}
                        alt='EC'
                    />
                </Navbar.Brand>
                {width < breakpoint ? (
                    <>
                        <Navbar.Toggle aria-controls='responsive-navbar-nav' data-bs-target='#responsive-navbar-nav' className='navBarECtoggle' aria-expanded={open}/>
                            <Fade in={open}>
                                <Navbar.Collapse id='responsive-navbar-nav' onChange={() => setOpen(!open)}>
                                    <Nav className='txxt'>
                                        <NavLink to='/debt' className='txxt-a'>Узнать задолженность</NavLink>
                                        <NavLink to='/pay' className='txxt-a'>Оплатить долг</NavLink>
                                        {/* <NavLink  to='/documents' className='txxt-a'>Документы</NavLink> */}
                                        <hr style={{height:'1px', color:'white', width:'100%', marginRight:'4rem', marginBlock:'0'}}/>
                                        <NavLink to='/info' className='txxt-a'>Полезная информация</NavLink>
                                        <hr style={{height:'1px', color:'white', width:'100%', marginRight:'4rem', marginBlock:'0'}}/>
                                        {/* <hr style={{height:'1px', color:'white', width:'100%', marginRight:'4rem', marginBlock:'0'}}/> */}
                                        <NavLink to='/about' className='txxt-a'>Офис компании</NavLink>
                                        <NavLink to='/license' className='txxt-a'>Документы</NavLink>
                                        <NavLink to='/requisites' className='txxt-a'>Реквизиты</NavLink>
                                        <NavLink to='/support' className='txxt-a'>Интернет приёмная</NavLink>
                                        <NavLink to='/careers' className='txxt-a'>Вакансии</NavLink>
                                    </Nav>
                                    <div className='NavContacts'>
                                        <a href='tel:83412940707' id='NavPhone'>{tel}</a>
                                        <span id='NavWorkingHours'>Пн-Пт, с 8-00 до 17-00 МСК</span>
                                        <NavLink to={
                                            authContext.isAuthenticated() ? '/dc' : '/login'
                                        }>
                                            <ButnotP name='Личный кабинет' id='navbarAdaptive' style={{marginRight: '-4rem'}}></ButnotP>
                                        </NavLink>
                                    </div>
                                    <div className='DropdownRow'>
                                        <span id='Ur'>Юридический адрес:</span>
                                        <span>пр. Дерябина, 3, Ижевск, республика Удмуртия, 426006</span>
                                    </div>
                                    <a href='mailto:collect@ec18.ru' className='DropdownLink'>collect@ec18.ru</a>
                                </Navbar.Collapse>
                            </Fade> 
                    </>
                ) : (
                    <>
                        <Nav className='txxt'>
                            <NavLink to='/debt' className='txxt-a'>Узнать задолженность</NavLink>
                            <NavLink to='/pay' className='txxt-a'>Оплатить долг</NavLink>
                            {/* <NavLink  to='/documents' className='txxt-a'>Договоры цессий</NavLink> */}
                            <Fade in={open} dimension="height">
                                <NavDropdown id='nav-dropdown' title='О компании' menuVariant='dark' align='center' show={show} onMouseEnter={showDropdown} onMouseLeave={hideDropdown} style={{fontFamily:"Overpass, sans-serif", paddingTop:'2px', paddingRight:'3px', color:'#ffffff', opacity:'1'}}>
                                        <NavDropdown.Item href='/info' style={{paddingLeft:'2rem',marginRight:'2rem' ,left:'-2rem', overflowX:'hidden'}}>Полезная информация</NavDropdown.Item>
                                        <NavDropdown.Item href='/license' style={{paddingLeft:'2rem',marginRight:'2rem' ,left:'-2rem', overflowX:'hidden'}}>Документы</NavDropdown.Item>
                                        <NavDropdown.Item href='/support' style={{paddingLeft:'2rem',marginRight:'2rem' ,left:'-2rem', overflowX:'hidden'}}>Интернет приёмная</NavDropdown.Item>
                                        <NavDropdown.Item href='/careers' style={{paddingLeft:'2rem',marginRight:'2rem' ,left:'-2rem', overflowX:'hidden'}}>Вакансии</NavDropdown.Item>
                                        {/* <NavDropdown.Divider/> */}
                                </NavDropdown>
                            </Fade>
                            <Fade in={open}>
                                <NavDropdown id='nav-dropdown' title='Контакты' menuVariant='dark' align='center' show={show1} onMouseEnter={showDropdown1} onMouseLeave={hideDropdown1} style={{fontFamily:"Overpass, sans-serif", paddingTop:'2px', paddingRight:'3px', color:'#ffffff', opacity:'1'}}>
                                    <div className='DropdownRow'>
                                        <span id='Ur'>Юридический адрес:</span>
                                        <span>пр. Дерябина, 3, Ижевск, республика Удмуртия, 426006</span>
                                    </div>
                                    <a href='mailto:collect@ec18.ru' className='DropdownLink'>collect@ec18.ru</a>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item href='/about' style={{paddingLeft:'2rem',marginRight:'2rem' ,left:'-2rem', overflowX:'hidden'}}>Офис компании</NavDropdown.Item>
                                    <NavDropdown.Item href='/requisites' style={{paddingLeft:'2rem',marginRight:'2rem' ,left:'-2rem', overflowX:'hidden'}}>Реквизиты</NavDropdown.Item>
                                    <NavDropdown.Item href='/support' style={{paddingLeft:'2rem',marginRight:'2rem' ,left:'-2rem', overflowX:'hidden'}}>Интернет приёмная</NavDropdown.Item>
                                </NavDropdown>  
                            </Fade>
                        </Nav>
                        <div className='NavContacts'>
                            <a href='tel:83412940707' id='NavPhone'>{tel}</a>
                            <span id='NavWorkingHours'>Пн-Пт, с 8-00 до 17-00 МСК</span>
                            <NavLink to={
                                authContext.isAuthenticated() ? '/dc' : '/login'
                            }>
                                <ButnotP name='Личный кабинет' id='LK' style={{marginRight: '-4rem', marginTop:'0.75rem'}} ></ButnotP>
                            </NavLink>
                        </div>
                    </>
                )}
                {/* <Navbar.Toggle aria-controls='responsive-navbar-nav' data-bs-target='#responsive-navbar-nav' className='navBarECtoggle'/>
                <Fade in={open}>
                    <Navbar.Collapse id='responsive-navbar-nav' aria-expanded={open} onClick={() =>setOpen(!open)}>
                        <Nav className='txxt'>
                            <NavLink to='/debt' className='txxt-a'>Узнать задолженность</NavLink>
                            <NavLink to='/pay' className='txxt-a'>Оплатить долг</NavLink>
                            <NavLink  to='/documents' className='txxt-a'>Документы</NavLink>
                            <NavLink to='/about' className='txxt-a'>О компании</NavLink>
                            <NavLink to='/contacts' className='txxt-a'>Контакты</NavLink>

                        </Nav>
                        <NavLink to={
                            authContext.isAuthenticated() ? '/dc' : '/login'
                        }>
                            <ButnotP name='Личный кабинет' style={{marginRight: '-4rem'}}></ButnotP>
                        </NavLink>
                    
                    </Navbar.Collapse>
                </Fade> */}
            </Container>
        </Navbar>
    </>
  )
})
