export const base= 'white'
export const blue = '#333FFF'
export const purple = '#6833FF'
export const bblue = '#0033a1'
export const lightb  = '#00aee6'
export const britishb = '#b9cfed'
export const sand =  '#bc9b6a'
export const luxgreen = '#0D362B'
export const luxgrey = '#111111'
export const tel = '8 (3412) 940 707'
export const linktophone = '83412940707'
export const workhours = 'Пн-Пт, с 8-00 до 17-00 МСК'

